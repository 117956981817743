
.vkeyboard {
    bottom: 50px;
    display: none;
    left: 50%;
    max-width: 1080px;
    padding: 0 50px;
    position: fixed;
    transform: translateX(-50%);
    width: 100%;
    z-index: 999;
}
.vkeyboard.active { display: block; }
.vkeyboard .react-simple-keyboard { box-shadow: 0 0 5px rgba(0,0,0,.25); padding-right: 0; }
.vkeyboard .hg-button.hg-functionBtn.hg-button-enter { display: none; }
.hg-theme-default .hg-row > div:last-child { margin-right: 5px; }