/*
     ___                  _    
    / __| ___ __ _ _ _ __| |_  
    \__ \/ -_) _` | '_/ _| ' \ 
    |___/\___\__,_|_| \__|_||_|
       
    All the following style are applied
    to the every results related component.

    Table of contents:
    -  # General
    -  # Filter menu
    -  # Business card

*/

/* # General */

#results {
  background-color: var(--grey);
  flex: 1;
  margin-top: -50px;
  position: relative;
  z-index: 4;
}

#results .enterprisesWrapper {
  display: flex;
  flex-flow: row wrap;
  max-width: 1920px;
  text-align: left;
  width: 100%;
  /* margin-right: 200px; */
  margin: 0 auto;
}

#results .resultsTop {
  font-size: 12px;
  padding: 0 25px 25px 25px;
}

#results .enterprisesWrapper .resultsList {
  display: flex;
  flex-flow: row wrap;
  min-height: calc(100vh - 250px);
  width: 100%;
}

#results .enterprisesWrapper.mapView .resultsList {
  flex-flow: column wrap;
  justify-content: flex-start;
  margin: 25px;
  min-width: 350px;
  width: 350px;
}

#results .enterprisesWrapper .resultsMap {
  height: 100%;
  min-width: 50vw;
}

#results .enterprisesWrapper.mapView .resultsMap {
  border-radius: 5px;
  bottom: 5px;
  flex: 1;
  /* height: calc(100vh - 239px); */
  height: calc(100vh - 96px);
  margin: 25px;
  /* overflow: hidden; */
  position: sticky;
  top: 25px;
  transition: 0.25s;
}

#results .enterprisesWrapper.mapView .card {
  flex-direction: row;
  height: 125px;
  margin: 0 0 15px 0;
  min-width: 400px;
  width: 400px;
}

#results .enterprisesWrapper.mapView .card .cardContent {
  padding-left: 0;
}

#results .enterprisesWrapper.mapView .card h3,
#results .enterprisesWrapper.mapView .card .qr {
  display: none;
}

#results .enterprisesWrapper.mapView .card .res_name {
  height: 50px;
  margin-bottom: 0;
  max-width: 230px;
  min-height: 50px;
  padding: 15px;
  border: none;
}

#results .enterprisesWrapper.mapView .card .cardRow {
  padding: 0 15px 15px 15px;
}

#results .enterprisesWrapper.mapView .card .res_name h2 {
  margin: 0;
}

#results .enterprisesWrapper.mapView .card .cardLogoWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  max-width: 100px;
  min-width: 50px;
  overflow: hidden;
  padding: 10px;
}

#results .enterprisesWrapper.mapView .card .cardLogo {
  position: relative;
  padding: 0px;
}

#results .enterprisesWrapper.mapView .card .cardLinks {
  border: none;
  flex: 0;
  flex-direction: column;
  height: auto;
  min-width: 35px;
  width: 35px;
}

#results .enterprisesWrapper.mapView .card .cardLinks a {
  align-items: center;
  display: flex;
  padding: 10px;
  text-align: right;
}

#results .enterprisesWrapper.mapView .card .cardLinks a span {
  display: none;
}

#results .enterprisesWrapper.mapView .card .cardLinks a .icon {
  margin: 0;
  order: 1;
  z-index: 1;
}

#results .errorMessage {
  left: 50%;
  max-width: max-content;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

#results .errorMessage span {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
  background-clip: text;
  font-family: "heebo Light", sans-serif;
  font-size: 50px;
}

/* Business cards when results are loading */

#results .enterprisesWrapper .card.loading-data .res_name h2 {
  display: block;
  height: 50px;
}

#results .enterprisesWrapper .card.loading-data .cardRow div span {
  height: 10px;
}

#results .enterprisesWrapper .card.loading-data .cardRow .res_telephone,
#results .enterprisesWrapper .card.loading-data .res_name h2,
#results .enterprisesWrapper .card.loading-data .cardRow div span {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
  min-height: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}


/* Filter menu */

#filter_menu {
  display: flex;
  font-size: 12px;
  gap: 20px;
  justify-content: space-between;
}

/* Filter button and options */
#filter_menu .filter_button {
  color: var(--grey-50);
  position: relative;
}

#filter_menu .accordion {
  position: absolute;
  right: 0;
  z-index: 500;
}

#filter_menu .accordion .accordionItemContent {
  display: flex;
  flex-flow: column nowrap;
}

#filter_menu .accordion .filter_item {
  min-width: 230px;
}

/* Filter view */
#filter_menu .rm_type {
  cursor: pointer;
  position: relative;
}

#filter_menu .rm_type.rm_view {
  display: flex;
  height: 35px;
}

#filter_menu .rm_type.rm_view .rm_view_item {
  align-items: center;
  color: var(--grey-50);
  display: flex;
  font-size: 10px;
  min-width: 170px;
  position: relative;
  text-align: center;
}

#filter_menu .rm_view .rm_view_active_border,
#filter_menu .rm_view .rm_view_hover_border {
  background: linear-gradient(135deg, var(--main) 0%, var(--sub) 100%);
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  min-width: 170px;
  position: absolute;
  transition: 0.5s;
  z-index: 0;
}

#filter_menu .rm_view .rm_view_hover_border {
  background: var(--grey-50);
  z-index: -1;
}

#filter_menu .rm_type.rm_view .rm_view_item {
  padding: 0 10px;
}

#filter_menu .rm_type.rm_view .rm_view_item.active {
  color: var(--sub);
}

#filter_menu .rm_type.rm_view .rm_view_item.active:nth-child(1n)~.rm_view_active_border,
#filter_menu .rm_type.rm_view .rm_view_item.active:nth-child(1n)~.rm_view_hover_border {
  left: 0;
}

#filter_menu .rm_type.rm_view .rm_view_item:nth-child(1n):hover~.rm_view_hover_border {
  left: 0 !important;
}

#filter_menu .rm_type.rm_view .rm_view_item.active:nth-child(2n)~.rm_view_active_border,
#filter_menu .rm_type.rm_view .rm_view_item.active:nth-child(2n)~.rm_view_hover_border {
  left: 170px;
}

#filter_menu .rm_type.rm_view .rm_view_item:nth-child(2n):hover~.rm_view_hover_border {
  left: 170px !important;
}

#filter_menu .rm_type.rm_view .rm_view_item .icon {
  border: 1px solid var(--grey-20);
  border-radius: 5px;
  height: 20px;
  padding: 3px;
  width: 25px;
}

#filter_menu .rm_type.rm_view .rm_view_item.active .icon {
  border: 1px solid var(--sub);
  color: var(--sub);
}

#filter_menu .rm_view .rm_view_item span {
  flex: 1;
}

@media only screen and (max-width: 768px) {
  #filter_menu .rm_type.rm_view .icon {
    display: none;
  }

  #filter_menu .rm_dropdown {
    left: unset;
  }

  #filter_menu .rm_type.rm_view .rm_view_item,
  #filter_menu .rm_view .rm_view_active_border,
  #filter_menu .rm_view .rm_view_hover_border {
    min-width: 130px;
  }

  #filter_menu .rm_type.rm_view .rm_view_item.active:nth-child(2n)~.rm_view_active_border,
  #filter_menu .rm_type.rm_view .rm_view_item.active:nth-child(2n)~.rm_view_hover_border {
    left: 130px;
  }

  #filter_menu .rm_type.rm_view .rm_view_item:nth-child(2n):hover~.rm_view_hover_border {
    left: 130px !important;
  }
}

@media only screen and (max-width: 350px) {
  #filter_menu .rm_type.rm_view .rm_view_item {
    padding: 0;
  }

  #filter_menu .rm_type.rm_view .rm_view_item,
  #filter_menu .rm_view .rm_view_active_border,
  #filter_menu .rm_view .rm_view_hover_border {
    min-width: 110px;
  }
}

/* # Business card */

.cardWrapper {
  display: inline-block;
  position: relative;
  text-align: center;
  width: auto;
}

.card {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
  color: var(--grey-50);
  display: flex;
  flex-flow: column nowrap;
  font-size: 13px;
  height: 391.5px;
  margin: 0 25px 50px 25px;
  overflow: hidden;
  position: relative;
  text-align: left;
  transition: 0.35s transform, 0.35s box-shadow;
  width: 252px;
  border: 1px solid var(--grey-10);
}

.card:hover,
.card.active {
  box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}

.card::after {
  background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
  bottom: -1px;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  transform-origin: center;
  transition: 0.25s;
}

.card:hover::after,
.card.active::after,
.card.hover::after {
  height: 3px;
}

.card .icon {
  height: 30px;
  margin-right: 10px;
  max-width: 30px;
  padding-bottom: 1px;
  padding: 5px;
  width: 30px;
}

/* Business card top icons */

.card .businessCardIcons {
  align-items: flex-end;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column nowrap;
  opacity: 0;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.25s;
  z-index: 1;
}

.enterprisesWrapper.mapView .card .businessCardIcons {
  right: 40px;
}

.card:hover .businessCardIcons {
  opacity: 1;
}

.card .businessCardIcons .icon {
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  margin: 0;
  max-width: 30px;
  padding: 6px;
  transition: 0.25s;
  width: 30px;
}

.card .businessCardIcons .icon:hover {
  background-color: var(--grey-10);
}

.card .businessCardIcons .icon.favoriteIcon {
  color: var(--favorite);
}

.card .businessCardIcons .businessCardShare {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-flow: row-reverse nowrap;
  overflow: hidden;
  position: absolute;
}

.card .businessCardIcons .businessCardShareIcons {
  display: flex;
  flex-flow: row-reverse nowrap;
  width: 0;
}

.card .businessCardIcons .businessCardShare:hover .businessCardShareIcons {
  width: auto;
}

.card .businessCardIcons .businessCardShare .icon {
  border-radius: 0;
}

.card .businessCardIcons .businessCardShare .SocialMediaShareButton--facebook .icon:hover {
  background-color: var(--facebook);
  color: white;
}

.card .businessCardIcons .businessCardShare .SocialMediaShareButton--linkedin .icon:hover {
  background-color: var(--linkedin);
  color: white;
}

.card .businessCardIcons .businessCardShare .SocialMediaShareButton--twitter .icon:hover {
  background-color: var(--twitter);
  color: white;
}

.card .businessCardIcons .businessCardShare .SocialMediaShareButton--whatsapp .icon:hover {
  background-color: var(--whatsapp);
  color: white;
}

/*  Logo and QR of the card */

.card .cardLogoWrapper {
  --moz-transform-style: preserve-3d;
  --webkit-transform-style: preserve-3d;
  height: 170px;
  transform-style: preserve-3d;
  transition: 0.35s transform;
}

.card .cardLogoWrapper a {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card .cardLogoWrapper a::after {
  content: unset;
}

.card .cardLogoWrapper .cardLogo {
  align-items: center;
  background-color: white;
  box-sizing: unset;
  padding: 25px;
  position: relative;
  z-index: 5;
}

.card .cardLogoWrapper .cardLogo img {
  max-height: 100%;
  max-width: 100%;
  transition: 0.2s;
}

.card.activated .cardLogoWrapper img,
.card .cardLogoWrapper .cardQr {
  transform: rotateY(-180deg);
}

.card .cardLogoWrapper .cardLogo,
.card .cardLogoWrapper .cardQr {
  --moz-backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.card .cardLogoWrapper .cardQr {
  background-color: white;
  z-index: 10;
}

.card .cardLogoWrapper .cardQr img {
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}

.card .cl_qr .icon {
  margin: 0;
}

.card .cl_qr {
  transition: 0.25s;
}

.card .cl_qr:hover {
  background-color: var(--grey-5);
}

/* Enterprise name */

.card .res_name {
  border-bottom: 1px solid var(--grey-10);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 55px;
  margin-bottom: 6px;
  max-height: 55px;
  padding: 0 15px 10px 15px;
}

.card h2 {
  color: black;
  font-size: 13px;
  margin: 0 0 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card h3 {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.3);
  font-family: "OpenSans", sans-serif;
  font-weight: 700;
  font-size: 11px;
  margin: 0 0 5px 0;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Card content */

.card .cardContent {
  display: flex;
  flex: 1 1;
  flex-flow: column nowrap;
  overflow: hidden;
  position: relative;
}

.enterprisesWrapper.mapView .card .cardContent::before {
  background-color: var(--grey-20);
  content: "";
  height: 60%;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
}

.card .cardContent .cardRow {
  align-items: center;
  display: flex;
  padding: 0 15px 5px 15px;
}

.card .cardContent .cardRow>* {
  flex: 1;
}

.card .cardContent .cardRow div {
  overflow: hidden;
}

.card .cardContent .cardRow span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.card .cardContent .card_address {
  padding: 0.2px 0;
}

/* Card buttons / links */

.card .cardLinks {
  border-top: 1px solid var(--grey-10);
  cursor: pointer;
  display: flex;
  font-size: 11px;
  height: 40px;
  margin-bottom: 2px;
  overflow: hidden;
  text-align: center;
}

.card .cardLinks a {
  align-items: center;
  color: var(--grey-50);
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding: 5px;
  text-decoration: none;
  transition: 0.25s;
  white-space: nowrap;
}

.card .cardLinks a:hover {
  background-color: var(--grey-5);
}

.card .cardLinks a::after {
  content: unset;
}

.card .cardLinks a .icon {
  height: 17px;
  max-width: 17px;
  padding-bottom: 0;
  width: 17px;
}

.card .cardLinks a .icon svg {
  padding-top: 2px;
}

.card .cardLinks a.cl_website .icon {
  margin: 0 5px 0 0;
}

.card .cardLinks a.cl_phone .icon svg {
  padding-top: 4px;
}

.card .cardLinks a.cl_phone .icon {
  margin: 0;
}

.card .cardLinks a.cl_bp {
  color: var(--sub);
}

.card .cardLinks a.cl_bp .icon {
  margin: 0 0 0 10px;
}

.card .cardLinks a .icon:hover #arrow-double-right,
.card .cardLinks a .icon:hover #arrow-right {
  transform: none;
}

.card .cardLinks a .icon {
  padding: 0;
}

#results .enterprisesWrapper .card .cardLinks .cl_phone,
#results .enterprisesWrapper.mapView .card .cardLinks .cl_phone {
  display: none;
}

#results .enterprisesWrapper.mapView .card .cardLinks .cl_phone {
  display: none;
}

.card .add_contact_message {
  display: block;
  font-size: 15;
  text-align: center;
}

.card .slick-slider,
.card .slick-list,
.card .slick-track,
.card .slick-slide div {
  height: 100%;
}

.card .cardLogoWrapper .cardLogo.inner {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  display: flex !important;
  justify-content: center;
}

.card .slick-track {
  display: flex !important;
}

.enterprisesWrapper.mapView .card .slick-slide div {
  width: 100px;
  max-width: 100%;
}

.enterprisesWrapper.mapView .card .cardLogoWrapper.multiple {
  padding: 0px !important;
}

.enterprisesWrapper.mapView .card .cardLogoWrapper .slick-slide {
  max-width: 100px;
  min-width: 100px;
  box-sizing: border-box;
  padding: 5px;
}

@media only screen and (max-width: 768px) {
  .card:hover {
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
    transform: scale(1);
  }

  .card .cardLinks .cl_phone {
    display: inline-block;
  }

  .card:hover::after {
    bottom: 0;
    height: 2px;
  }

  #results .enterprisesWrapper .card .cardLinks .cl_phone,
  #results .enterprisesWrapper.mapView .card .cardLinks .cl_phone {
    display: inline-block;
  }

  .card .cardLinks span {
    display: none;
  }

  .card .cardLinks span.show {
    display: block;
  }

  .card .cardLinks a {
    padding-top: 7px;
  }

  .card .cardLinks a .icon {
    height: 20px;
    max-width: 20px;
    width: 20px;
  }

  .card .qr {
    opacity: 0.5;
  }

  .card .cardLinks a.cl_phone .icon {
    height: 25px;
    max-width: 25px;
    width: 25px;
  }

  .cardWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  #results .enterprisesWrapper.mapView .card {
    width: 100%;
    min-width: unset;
  }

  .card:hover,
  #results .enterprisesWrapper.mapView .card.active,
  #results .enterprisesWrapper.mapView .card.hover {
    transform: none;
  }

  .card .cardLinks a.cl_website .icon {
    margin: 0;
  }
}

.card.open .res_name {
  border-bottom: 1px solid var(--success);
}

.card.closed .res_name {
  border-bottom: 1px solid rgb(255, 80, 20);
}

.card.almost .res_name {
  border-bottom: 1px solid #d59624;
}



/* Css For Advertisement Banner */

.enterprisesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Align items to the left */
  align-items: stretch; /* Align items to the same height */
  gap: 20px; /* Space between elements */
  padding: 0 20px; /* Prevent content from touching screen edges */
}

.resultsList {
  flex: 1; /* Take remaining space */
}

.verticalLine {
  width: 1px;
  background-color: #ccc; /* Adjust color as needed */
  height: auto; /* Match parent container height */
}

.advertisementBannersForDesktop {
  flex-shrink: 0; /* Prevent shrinking */
  width: 250px; /* Fixed width */
  margin-right: 50px;
}

/* Hide vertical line on mobile and show ads within results */
@media (max-width: 768px) {
  .enterprisesWrapper {
    flex-direction: column; /* Stack elements vertically */
    padding: 0 10px; /* Adjust padding for smaller screens */
  }

  .verticalLine {
    display: none; /* Hide vertical line on mobile */
  }

  .advertisementBannersForDesktop {
    width: 100%; /* Make ads take full width */
    margin-right: 0; /* Reset margin */
  }
}


@media (min-width: 1024px) {
  .advertisementBannerMobile {
    display: none;
  }
}

/* Styling for ads within results */
.advertisementBanner {
  padding: 10px;
  margin: 15px 0;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #ddd;
}


.horizontalLine {
  border-top: 1px solid #ccc;
  margin-top: 10px;
}


.advertisementCardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.advertisementCardImage {
  width: 100%;
  height: 200px;
  overflow: hidden;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}


.loadingScreenForAds {
  display: flex;
  justify-content: center;
  /* Horizontally centers the content */
  align-items: center;
  /* Vertically centers the content */
  height: 100vh;
  /* Full viewport height */
}


/* Container for the advertisement banners */
.advertisementBanners {
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 20px;
}

/* Individual advertisement card styling */
.advertisementCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  max-height: 1200px;
  width: 300px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
}

.advertisementCard:hover {
  transform: translateY(-5px);
}

/* Wrapper for the slider images */
.advertisementCardImage {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  justify-content: center;

  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.adImageWrapper {
  width: 100%;
  height: 100%;
  /* Ensures it uses the full height available within the parent */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* To ensure no overflow if the image exceeds container size */
}

.advertisementBannersBusinessPageContainer {
  margin-right: 5px;
  margin-top: 5px;
}

/* only for mobile advertismentbannerbusinesspagecontainer should have width: 100% */ 
@media (max-width: 768px) {
  .advertisementBannersBusinessPageContainer {
    width: 100%;
  }
}

.adImageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Makes the image fully cover the container */
  /* border-radius: 8px 8px 0 0; */
}

.adImage { 
  height: 200px !important  ;
}


/* Slider styling */
.slick-dots {
  bottom: 10px;
}

.slick-prev,
.slick-next {
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: background-color 0.3s;
  z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slick-prev::before,
.slick-next::before {
  font-size: 20px;
}