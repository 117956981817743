/*
     ___     _                  
    | _ \_ _(_)_ ____ _ __ _  _ 
    |  _/ '_| \ V / _` / _| || |
    |_| |_| |_|\_/\__,_\__|\_, |
                           |__/ 

    All the following style are applied
    to the every privacy related component.

    Table of contents:
    -  # We care
    -  # Privacy policy

*/

/* # We care */

    #privacy { display: flex; flex-direction: column; position: relative; z-index: 0 }
    #privacy .pBg, 
    #privacy .pBgOverlay {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: -1
    } 
    #privacy .pBg {
        background-image: url('../imgs/privacyBg.webp');
        background-position: center bottom;
        background-size: cover;
    }
    #privacy h2 { font-size: 40px; margin-top: 0 }
    #privacy .pView { height: 25vh }
    #privacy .subBtn {
        display: block;
        height: 35px;
        line-height: normal;
        margin: 0 auto;
        text-align: center;
        width: max-content;
    }
    #privacy .subBtn { bottom: 35px; left: 0; position: absolute; right: 0 }
    #privacy .icon { height: 20px; margin: 0 10px 0 0; padding: 0; width: 20px }
    #privacy ul li { display: inline-block; padding: 5px 0 5px 0; width: 100% }
    #privacy .button { margin: 5px auto }
    
    /* Home and share button */

    #privacy .shareBtn,
    #privacy .homeBtn { 
        box-shadow: 0 0 25px rgba(0,0,0,.2);
        border-radius: 50px;
        cursor: pointer;
        position: fixed;
        transition: .5s;
        z-index: 75;
    }
    #privacy .shareBtn {
        background-color: var(--grey-10);
        color: var(--grey-50);
        bottom: 85px;
        height: 40px;
        overflow: hidden;
        right: 30px;
        width: 40px;
    }
    #privacy .shareBtn:hover { height: 240px }
    #privacy .shareBtn .shareIcon { color: var(--grey-90); cursor: default }
    #privacy .shareBtn .icon { height: 40px; padding: 10px; width: 40px }
    #privacy .homeBtn {
        background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
        bottom: 25px;
        height: 50px;
        right: 25px;
        width: 50px;
    }
    #privacy .homeBtn:hover { transform: scale(1.1) }
    #privacy .homeBtn .icon {
        color: var(--grey-90);
        display: block;
        height: 50px;
        padding: 10px;
        width: 50px;
    }
    #privacy .shareBtn .SocialMediaShareButton { transition: .25s }
    #privacy .shareBtn .SocialMediaShareButton--facebook:hover { background-color: var(--facebook); color: white }
    #privacy .shareBtn .SocialMediaShareButton--linkedin:hover { background-color: var(--linkedin); color: white }
    #privacy .shareBtn .SocialMediaShareButton--twitter:hover { background-color: var(--twitter); color: white }
    #privacy .shareBtn .SocialMediaShareButton--whatsapp:hover { background-color: var(--whatsapp); color: white }
    #privacy .shareBtn .SocialMediaShareButton--pinterest:hover { background-color: var(--pinterest); color: white }
    #privacy .shareBtn .SocialMediaShareButton--email:hover { background-color: var(--sub); color: white }

    /* Privacy congratulations message block */ 
    
    #privacy .pMessage {
        background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
        box-shadow: 0 0 100px 10px rgba(0,0,0,0.25);
        height: max-content;
        padding: 50px;
        text-align: center;
        width: 100%;
    }
    #privacy .pMessage h2 { margin: 0 0 25px 0; text-align: center }
    #privacy .pMessage h3 { font-size: 25px; margin-bottom: 0 !important }

    /* Privacy row */

    #privacy .pRow {    
        background-color: var(--grey-20);
        border-radius: 5px;
        display: inline-flex;
        margin: 80px auto;
        max-width: 1280px;
        position: relative;
        width: 100%;
        z-index: 50;
        flex-flow: row wrap;
    }
    #privacy .first{
        margin-top: 17vh !important;
    }
    #privacy .pRow::before {
        background: var(--grey);
        bottom: -80px;
        content: '';
        height: 1px;
        position: absolute;
        width: 100%;
    }
    #privacy .pRow.noBorder { margin-bottom: 100px }
    #privacy .pRow.noBorder::before { content: unset }
    #privacy .pRow h2 {
        color: var(--grey-50);
        font-family: 'heebo Thin', sans-serif;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 25px;
    }
    #privacy .pRow .pBlockHeader { padding: 20px }
    #privacy .pRow .pBlockPart { break-inside: avoid; padding: 20px }
    #privacy .pRow .pRowImage {
        border-radius: 5px 0 0 5px;
        height: max-content;
        margin: 0 auto;
        max-width: 300px;
        min-width: 300px;
        padding: 25px;
        position: sticky;
        top: 0;
        z-index: -1;
    }
    #privacy .pRow.pEven .pRowImage { order: 1 }
    #privacy .pRow .pRowImage img { border-radius: 5px; width: 100%;}
    #privacy .pRow .pRowContent {
        background-color: var(--grey-10);
        border-radius: 0 5px 5px 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-family: 'OpenSansLight', sans-serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        min-width: 350px;
        padding: 20px 20px 85px 20px;
        position: relative;
        text-align: left;
        z-index: 9;
    }
    #privacy .pRow.pEven .pRowContent { border-radius: 5px 0 0 5px }
    #privacy .pRow .pBlockPart h3 { margin-top: 0 }
    #privacy .pRow .pBlockContent { transform-origin: top; transition: .25s }

    /* GDPR block */

    #privacy .pGDPR {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 320px;
        height: max-content;
        justify-content: space-between;
        margin-bottom: 75px;
        min-height: max-content;
        padding: 75px 25px;
        position: relative;
        text-align: center;
        z-index: 50;
    }
    #privacy .pGDPR::after {
        background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0.75;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    #privacy .pGDPR h2 { font-size: 50px; font-family: 'heebo Thin', sans-serif; margin: 0; }
    #privacy .pGDPR h3 { font-size: 30px; margin: 0; }
    #privacy .pGDPR .subBtn a { color: var(--grey-90) }
    #privacy .pGDPR a::after { content: unset ; }


    /* Privacy bookmarking block */

    #privacy .pBookmarking { display: none }
    #privacy .pBookmarking h2 { font-size: 25px;  line-height: 30px;  margin-bottom: 25px }

    /* Privacy media queries */

    @media only screen and (max-width: 768px) {
        #privacy .pRow h2 { font-size: 30px; line-height: 40px }
        #privacy .pRow .pRowContent { border-radius: 0; min-width: 100% }
        #privacy .pRow.pEven .pRowImage { order: unset }
        #privacy .pView { height: 221px;}
    }
    @media only screen and (max-width: 370px) { 
        #privacy .pMessage { padding: 25px } 
        #privacy .pMessage h2 { font-size: 30px }
        #privacy .pMessage h3 { font-size: 20px }
        #privacy .pView { height: 177px;}
    }

/* # Privacy policy */

    #privacy.pol { z-index: unset }
    #privacy #policy h1, #privacy #policy h2 {
        border-bottom: 1px solid var(--grey-20);
        font-family: 'heebo Light', sans-serif;
        font-size: 50px;
        line-height: initial;
        margin: 50px 0 50px 0;
        padding-bottom: 25px;
        text-align: center;
        width: 100%;
    }
    #privacy h2#terms { border-top: 1px solid var(--grey-20); padding-top: 25px }
    #privacy #policy h3 { 
        align-items: center; 
        display: flex; 
        font-size: 30px;
        margin: 0;
        margin-bottom: 20px;
    }
    #privacy #policy h4 {
        background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
        border-radius: 5px;
        font-family: 'NettoOT-Bold', sans-serif;
        margin: 0;
        padding: 5px 10px;
        width: max-content;
    }
    #privacy #policy p { margin-bottom: 25px }
    #privacy #policy h5 { font-family: 'heebo Light', sans-serif; font-size: 20px; margin: 20px 0 }
    #privacy #policy .pTitleWrapper { align-items: center; display: flex; margin-bottom: 25px }
    #privacy #policy .pTitleWrapper span { color: var(--main); font-size: 35px; margin-right: 20px; padding-top: 2px }
    #privacy #policy .pTitleWrapper .pTitle { border-left: 2px solid var(--main); padding-left: 25px }

    /* Privacy policy header */

    #privacy #policy .privacyHead { background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%); overflow: hidden }
    #privacy #policy .privacyHead .privacyHeadContent { 
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between; 
        margin: 0 auto; 
        max-width: 1000px; 
        padding: 50px;
    }
    #privacy #policy .privacyHead .privacyLogo { flex: 1; min-width: 250px; width: 350px }
    #privacy #policy .privacyHead img.logo { margin-bottom: 10px; max-width: 450px; transition: 0.5s; width: 100% }
    #privacy #policy .privacyHead img.logo:hover { transform: scale(1.05) }
    #privacy #policy .privacyHead a { color: white }
    #privacy #policy .privacyHead a::after { content: unset }
    #privacy #policy .privacyHead p { color: white; font-size: 14px; line-height: 25px; text-align: justify }

    /* Privacy policy ID */

    #privacy #policy .privacyID {
        background-color: var(--grey-10);
        border-radius: 5px;
        box-shadow: 0 0 100px 10px rgba(0,0,0,0.25);
        height:  280px;
        margin-left: 25px;
        min-width: 490px;
        padding: 25px;
        position: relative;
        transition: 0.5s;
        width: 450px;
        z-index: 2;
    }
    #privacy #policy .privacyID:hover { transform: scale(1.05) }
    #privacy #policy .privacyID h2 {
        border-bottom: 1px solid var(--grey-20);
        color: var(--grey-50);
        font-family: 'heebo Light', sans-serif;
        font-size: 20px;
        margin: 0 0 25px 0;
        padding-bottom: 15px;
        text-align: center;
    }
    #privacy #policy .privacyID .idCardImage { display: inline-block; width: 126px }
    #privacy #policy .privacyID .idCardImage img { width: 100% }
    #privacy #policy .privacyID .idCardContent {
        display: inline-block;
        padding-left: 25px;
        vertical-align: top;
        width: calc(100% - 126px);
    }
    #privacy #policy .privacyID a {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        color: var(--grey-50); 
        display: inline-block;
        margin-bottom: 15px;
        padding: 5px 10px;
        position: relative;
        transition: .5s;
        width: auto;
    }
    #privacy #policy .privacyID a:hover { color: white }
    #privacy #policy .privacyID a:after {
        background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
        border-radius: 3px;
        bottom: 0;
        color: white;
        content: "";
        display: block;
        left: 0;
        opacity: .65;
        position: absolute;
        right: 0;
        top: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.5s;
        z-index: -1;
    }
    #privacy #policy .privacyID a:hover:after { transform: scaleX(1) }

    /* Privacy policy quote */

    #privacy #policy .privacyQuote {
        background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
        color: white;
        padding: 50px; 
        text-align: center;
    }
    #privacy #policy .privacyQuote .quoteWrapper { margin: 0 auto; max-width: 500px }
    #privacy #policy .privacyQuote h2 { border: none; font-size: 25px; margin: 0 0 10px 0; padding: 0 }
    #privacy #policy .privacyQuote h3 {
        border: none;
        display: block;
        font-family: 'openSans', sans-serif;
        font-size: 15px;
        font-style: italic;
        margin: 10px 0 0 0;
        padding: 0;
    }
    #privacy #policy .privacyQuote .icon:first-child { display: block; margin: 0 auto 0 0 }
    #privacy #policy .privacyQuote .icon { display: block; height: 30px; margin: 0 0 0 auto; width: 30px }

    /* Privacy policy user blocks */

    #privacy #policy .pUserBlock {
        background-color: var(--grey-10);
        font-size: 16px;
        line-height: 25px;
    }
    #privacy #policy .pUserBlock.pBlockRight { background-color: var(--grey-20) }
    #privacy #policy .pUserBlock.pBlockRight .pBlockImage { order: 1 }
    #privacy #policy .pUserBlock .pUserBlockWrapper { display: flex; flex-flow: row wrap; margin: 0 auto; max-width: 1000px }
    #privacy #policy .pUserBlock .pBlockImage { 
        flex: 1;
        height: 397px;
        margin: 0 auto;
        max-width: 350px;
        min-width: 320px;
        padding: 50px 0 50px 50px; 
        position: sticky; 
        top: 0; 
        width: calc(100% - 600px);
        z-index: 0;
    }
    #privacy #policy .pUserBlock .pBlockImage img { height: auto; max-width: 500px; width: 100% }
    #privacy #policy ol.unordered { font-style: italic; margin-bottom: 25px; padding-left: 20px }
    #privacy #policy ol.unordered li { border-bottom: 1px dotted var(--grey-50); padding: 10px 0 10px 0; width: 100% }
    #privacy #policy .privacyBody {
        font-size: 16px;
        line-height: 25px;
        margin: 0 auto 50px auto;
        max-width: 1000px;
        padding: 50px;
        width: 100%;
    }
    #privacy #policy .privacyBody ol { padding-left: 30px; }
    #privacy #policy .privacyBody.privacyTermsBody { padding-top: 0; }
    #privacy #policy .privacyBody h3 { margin: 50px 0; }
    #privacy #policy .pUserBlock.pBlockRight .pBlockContent { background-color: var(--grey-20); }
    #privacy #policy .pUserBlock .pBlockContent {
        background-color: var(--grey-10);
        flex: 1;
        min-width: 350px;
        padding: 50px;
        position: relative;
        width: 600px;
        z-index: 1;
    }

    /* Privacy policy media queries */

    @media only screen and (max-width: 824px) { 
        #privacy #policy .privacyHead .privacyLogo { margin-bottom: 25px; min-width: 100% }
        #privacy #policy .privacyID { margin: 0 auto } 
    }
    @media only screen and (max-width: 950px) { #privacy #policy h4 { width: unset } }
    @media only screen and (max-width: 865px) {
        #privacy #policy .privacyHead .privacyHeadContent{ max-width: 650px;}
    }
    @media only screen and (max-width: 770px) {
        #privacy #policy .privacyID { height: max-content; min-width: 250px }
        #privacy #policy .privacyID .idCardImage {
            display: block;
            margin: 0 auto 25px auto;
            max-width: 250px;
            width: 100%;
        }
        #privacy #policy .privacyID .idCardContent { padding: 0; width: 100% }
        #privacy #policy .pUserBlock.pBlockRight .pBlockImage { order: unset }
        #privacy #policy .pUserBlock .pBlockImage { height: max-content; margin-top: 50px; padding: 0 }
        #privacy #policy .pUserBlock .pBlockContent { min-width: 100% }
    }
    @media only screen and (max-width: 400px) {
        #privacy #policy .pUserBlock .pBlockImage { max-width: 250px; min-width: 250px }
    }