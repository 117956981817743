/*
     ___         _                                      
    | _ )_  _ __(_)_ _  ___ ______  _ __  __ _ __ _ ___ 
    | _ \ || (_-< | ' \/ -_|_-<_-< | '_ \/ _` / _` / -_)
    |___/\_,_/__/_|_||_\___/__/__/ | .__/\__,_\__, \___|
                                   |_|        |___/     
   
    All the following style are applied
    to the business page and all its components

    Table of contents:
    -  # General
    -  # Top
    -  # Menu
    -  # Page

*/

/* # General */

    .Environment{
        background: var(--grey);
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        z-index: 1;
    }
    .Environment.drawer::before { background-color: rgba(0,0,0,.5); z-index: 999 }
    .bp {
        display: flex;
        flex-direction: column;
        height: max-content;
        margin-top: -50px;
        min-height: calc(100vh - 40px);
    }
    .bp .bpWrapper {
        align-content: flex-start;
        background-color: white;
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        position: relative;
        width: 100%;
        z-index: 150;
    }
    .bp .bpWrapper #pagination{ margin-top: auto; }
    .bp section { 
        flex: 1; 
        min-height: calc(100vh - 125px) !important; 
        min-width: 250px; 
        position: relative; 
        z-index: 1;
    }
    .bp section h2 { display: none !important; }
    .bp section .close::after { border-bottom: none; transform: scale(1) }
    .bp .bpName { flex: 1;  max-width: calc(100% - 650px) }
    .bp .bpMenuMobile { display: none }
    
    /* disabled this because it gave issues with the contactform */
    /* .bp .contactSocialMedia { z-index: 1;} */
    .bpWrapper.active .contactSocialMedia { pointer-events: none;}
    .bp .contactSocialMedia, .bp section, .bp .bpMenu {min-height: 782.7px; }
    .bp .contactSocialMedia a::after { content: unset }
    .bp .contactSocialMedia ul{ z-index: 0 }
    @media only screen and (max-width: 768px) { .bp { margin-top: 0; overflow: visible; }}
    @media only screen and (max-width: 589px) { .bp .contactSocialMedia { border-radius: 0 } }

/* # Top */

    .bp .bpTop { 
        align-items: center;
        background-color: var(--grey);
        border-bottom: 1px solid var(--grey-5);
        display: flex; 
        justify-content: space-between;
        padding: 15px;
        z-index: 50;
    }
    .bp .bpTop.active { transition: .25s; z-index: 151; min-height: 87.3px;}
    .bp .bpTop h1,
    .bp .bpMenu .bpEntrepreneurNameMobile h1 {
        font-family: 'heebo Thin', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        margin: 0;
        overflow: hidden;
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .bp .bpTop h2,
    .bp .bpMenu .bpEntrepreneurNameMobile h2 { 
        color: var(--grey-50);
        font-family: 'heebo', sans-serif;
        font-size: 12px; 
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .bp .bpTop .backToResults {
        color: var(--sub);
        cursor: pointer;
        display: inline-block;
        font-size: 12px; 
        margin-top: 10px;
    }
    .bp .bpTop .backToResults::after { content: unset }
    .bp .bpTop .backToResults .icon { height: 15px; margin-right: 15px; transition: .5s; width: 15px }
    .bp .bpTop .backToResults:hover .icon { transform: translateX(-5px) }
    @media only screen and (max-width: 869px) { .bp .bpTop { display: none } }

/* # Menu */

    .bp .bpMenu {
        background: var(--grey);
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 0;
        width: 280px;
        border-right: 1px solid var(--grey-10);
        height: auto;
    }
    .bp .bpMenu .bpLogo {
        align-items: center;
        background-color: white;
        box-sizing: unset;
        display: flex !important;
        height: 175px;
        justify-content: center;
        overflow: hidden;
        padding: 15px;
        position: relative;
    }
    .bp .bpMenu .bpLogo.inner {
        background-color: unset;
        margin-bottom: 0;
        padding: 0;
    }
    .bp .bpMenu .slick-slide { min-width: 280px; height: 100%; }
    .bp .bpMenu .slick-track { display: flex;}
    .bp .bpMenu .bpLogo img { max-height: 100%; max-width: 100%; padding: 4px; position: absolute;}

    .bp .bpMenu .slick-slider,
    .bp .bpMenu .slick-list,
    .bp .bpMenu .slick-track,
    .bp .bpMenu .slick-slide div { height: 100%; }

    .bp .bpMenu .bpEntrepreneurNameMobile { display: none }


    .bp .bpMenu .bpMenuItems li { 
        list-style-type: none;
        height: 46px;
        overflow: hidden;
        transition: 0.2s;
    }
    .bp .bpMenu .bpMenuItems li.active {
        height: max-content;
        box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
    } 

    .bp .bpMenu .bpMenuItems a {
        color: var(--grey-50);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        padding: 15px 20px;
        text-transform: uppercase;
        transition: .25s; 
        width: 100%;
    }
    .bp .bpMenu .bpMenuItems a::after { content: unset }
    .bp .bpMenu .bpMenuItems a.active {
        background-color: white;
        border-left: 4px solid var(--main);
        box-shadow: -3px 0 20px rgba(0,0,0,0.05);
        color: var(--grey-90);
        padding-left: 28px;
    }
    .bp .bpMenu .bpMenuItems a:hover { background-color: var(--grey-5); padding-left: 30px }
    .bp .bpMenu .bpMenuItems a.active:hover { background-color: white; padding-left: 28px }

    .bp .bpMenu .bpMenuItems .item_wrapper { 
        height: 0px;
        transform: scaleY(0);
        transition: 0.25s;
        transform-origin: top;
        border-bottom: 1px solid var(--grey-20);
    }
    .bp .bpMenu .bpMenuItems .item_wrapper.active { height: max-content; transform: scaleY(1);}
    .bp .bpMenu .bpMenuItems .item_wrapper span { 
        display: block;
        padding: 7px 10px 7px 35px;
        transition: 0.25s;
        font-size: 13px;
        cursor: pointer;
    }
    .bp .bpMenu .bpMenuItems .item_wrapper span:hover { padding-left: 45px; }
    .bp .bpMenu .bpMenuItems .item_wrapper span.active{
        padding-left: 45px;
        border-left: 4px solid var(--sub);
        color: var(--sub);
        
    }

/* contact form */
    #contact_form  .checkBox, .radio { 
        border: unset;
        border-radius: unset;
        float: unset;
        height: unset;
        margin-right: unset;
        padding: unset;
        width: unset;
    }
    #contact_form .close .icon::after {  border-bottom: unset; width: unset; }

    @media only screen and (max-width: 869px) { 
        .bp .bpMenu .bpEntrepreneurNameMobile { display: block; max-width: calc(100% - 135px); text-align: center }
        .bp .bpMenu .bpEntrepreneurNameMobile h1 { max-width: 100% }
        .bp .contactSocialMedia, .bp section, .bp .bpMenu { min-height: unset; }
        .bp .bpMenu { 
            align-items: center; 
            flex-direction: row; 
            justify-content: space-between; 
            order: -1; 
            width: 100%; 
            z-index: 0;
        }  
        .bp .bpMenu.active {
            z-index: 9999;
        }
        .bp .bpMenu > div {  flex: 1 }
        .bp .bpMenu .bpLogo {
            border-radius: 100%;
            margin: 15px;
            max-height: 80px;
            max-width: 80px;
            min-height: 80px;
            min-width: 80px;
            padding: 0;
            position: relative;
        }
        .bp .bpMenu .bpLogo img {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        .bp .bpMenu h1,
        .bp .bpMenu h2 { white-space: normal }
        .bp .bpMenu .bpMenuMobile {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 15px;
        }
        .bp .bpMenuMobile { display: block; margin: 0 15px; max-width: 30px }
        .bp .bpMenu .bpMenuMobile .icon { margin-left: auto }
        .bp .bpMenu .bpMenuItems {
            position: fixed;
            background: var(--grey-5);
            height: 100%;
            z-index: 9999;
            top: 0;
            width: 300px;
            transition: .25s;
        }

    .bp .bpMenu .slick-slide { 
        min-width: 80px;
        max-width: 80px;
        width: 80px;
        height: 80px;
     }

    .bp .bpMenu .bpLogo img { max-height: 100%; max-width: 100%; padding: 4px }

    .bp .bpMenu .slick-slider,
    .bp .bpMenu .slick-list,
    .bp .bpMenu .slick-track,
    .bp .bpMenu .slick-slide div { height: unset; }
    .bp .bpMenu .bpLogo.inner { margin: 0;}

    }
    @media only screen and (max-width: 560px) { 
        .contactSocialMedia .socialMedia_0 { display: flex; }
        .mapWrapper { height: unset;}
    }
    @media only screen and (max-width: 467px){
        .bp .bpMenu .bpLogo {
            max-height: 50px;
            max-width: 50px;
            min-height: 50px;
            min-width: 50px;
        }

        .bp .bpMenu .slick-slide { 
            min-width: 50px;
            max-width: 50px;
            width: 50px;
            height: 50px;
         }
    }

/* # Redirect message */

.redirect_message_wrapper {
    background-color: rgba(0,0,0,.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}
.redirect_message_wrapper .redirect_message {
    align-items: center;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-flow: column nowrap;
    font-size: 20px;
    justify-content: center;
    left: 50%;
    max-height: 400px;
    max-width: 750px;
    min-height: 200px;
    min-width: 450px;
    overflow: hidden;
    padding: 25px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
}
.redirect_message_wrapper .redirect_message::after {
    background: linear-gradient(45deg, var(--sub) 0%, var(--main) 100%);
    content: "";
    height: 5px;
    width: 100%;
    bottom: 0;
    position: absolute;
}
.redirect_message_wrapper .redirect_sorry { display: block; font-size: 30px; margin-bottom: 10px; }
.redirect_message_wrapper .redirect_home { font-size: 15px; margin-top: 10px; }
.redirect_message_wrapper .redirect_button {
    border-radius: 5px;
    color: var(--sub);
    cursor: pointer;
    font-size: 15px;
    margin-top: 20px;
}

.success_badge {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--success);
    z-index: 99;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.success_badge .icon { 
    width: 20px;
    height: 20px;
    cursor: pointer;
}