.infoBar {
    background: white;
    border-right: 1px solid var(--grey-20);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    height: 100vh;
    left: 0;
    position: absolute;
    transition: 0.2s;
    width: 300px;
    z-index: 99999;
    overflow: hidden;
}
.infoBar.mobile { transition: 0s !important;}
.infoBar:hover, .infoBar.hidden:hover { left: 0; }
.infoBar.hidden { left: -260px ; }
/* The toggle pin */
.infoBar_header .mapDrawerPin {
    color: var(--map);
    right: 10px; 
    top: 10px;
    position: absolute;
    z-index: 9;
}
.infoBar_header .mapDrawerPin .icon { height: 20px; width: 20px; }
.mapDrawerPin.mobile {
    color: white;
    left: 10px; 
    top: 10px;
    position: absolute;
    z-index: 9;
    border: 1px solid var(--grey-20);
    border-radius: 100%;
    padding: 5px;
    background-color: var(--info);
}
.infoBar_header .mapDrawerPin.mobile.hide .icon { height: 20px; width: 20px; }

.infoBar_header {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid var(--grey-20);
    /* box-shadow: 0px 0px 15px rgba(0,0,0,0.2); */
    display: flex;
    height: 100px;
    padding: 55px 15px;
    width: 100%;
}
.infoBar_header figure {
    height: 90px;
    position: relative;
    width: 100%;
}
.infoBar_header figure a {
    height: 90px;
    position: absolute;
    width: auto;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 5px;
}
.infoBar_header figure a::after { content: unset;}

.infoBar_header figure img {
    height: 90%;
}
.infoBar_titles {
    display: flex;
    width: 100%;
    background-color: var(--grey-5);
    border-bottom: 1px solid var(--grey-20) ;
}
.infoBar .infoBar_titles .infoBar_title{
    color: var(--grey-90);
    display: block;
    padding: 10px;
    border-right: 1px solid var(--grey-20);
    font-size: 15px;
    text-transform: capitalize;
    cursor: pointer;
}
.infoBar .infoBar_titles .infoBar_title.active {
    color: var(--sub);
    box-shadow: inset 0px 0px 15px rgba(0,0,0,0.1);
}
.infoBar .message_wrapper { 
    padding: 10px;
    border: 1px solid var(--grey-20);
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.05);
    margin: 10px 15px;
    background: white;
}
.message { display: flex; align-items: center;}
.message i { 
    width: 30px;    
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    margin-right: 10px;
    font-size: 25px !important;
}
.message_wrapper.danger .message i, .message_wrapper.danger .message .message_location { color: var(--error);}
.message_wrapper.info .message i, .message_wrapper.info .message .message_location { color: var(--success);}
.message_wrapper.warning .message i, .message_wrapper.warning .message .message_location { color: var(--main);}
.message_wrapper .message i, .message_wrapper .message .message_location { color: var(--grey-90);}

.message .message_location {
    width: 100%;
    white-space: nowrap;
    font-weight: 900;
    font-size: 15px;
    margin-bottom: 5px;
    color: var(--grey-100);
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message_title {
    display: block;
    color: var(--grey-70);
}
.message_image {
    width: 100%;
    height: 120px;
    position: relative;
    margin: 10px auto;
    overflow: hidden;
}
.message_image img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--grey-30);
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}

.message a { font-size: 12px; color: var(--sub); padding-bottom: 5px;}
.message a::after {bottom: -2px; transform-origin: left;}
.message a:hover::after {transform: scaleX(1);}
.message_dates {
    display: flex;
    margin-top: 5px;
}
.message_dates b{ margin-right: 5px;}
.message_dates { font-size: 11px;}
.messages_button { 
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 99999;
}
.messages_button .button.buttonIcon { width: 50px !important; height: 50px !important; border-radius: 100% !important;}
@media only screen and (max-width: 480px){
    .messages_button { 
        display: flex;
    }
}