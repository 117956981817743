/* 
  ___               _   
 | _ \  ___   ___  | |_ 
 |   / / _ \ / _ \ |  _|
 |_|_\ \___/ \___/  \__|
                                                                                                
*/

/* HEEBO */
/* 
    @font-face { font-display: swap; font-family: heebo; font-weight: unset; src: url('../fonts/heebo/Heebo-Regular.ttf') }
    @font-face { font-display: swap; font-family: heeboLight; font-weight: unset; src: url('../fonts/heebo/Heebo-Light.ttf') }
    @font-face { font-display: swap; font-family: heeboThin; font-weight: unset; src: url('../fonts/heebo/Heebo-Thin.ttf') }
    @font-face { font-display: swap; font-family: heeboBold; font-weight: unset; src: url('../fonts/heebo/Heebo-Bold.ttf') } */

/* OPEN SANS */

@font-face {
    font-display: swap;
    font-family: openSans;
    font-weight: unset;
    src: url('../fonts/openSans/OpenSans-Regular-webfont.woff')
}

@font-face {
    font-display: swap;
    font-family: openSansLight;
    font-weight: unset;
    src: url('../fonts/openSans/OpenSans-Light-webfont.woff')
}

@font-face {
    font-display: swap;
    font-family: openSansBold;
    font-weight: unset;
    src: url('../fonts/openSans/OpenSans-Bold-webfont.woff')
}

@font-face {
    font-display: swap;
    font-family: openSansItalic;
    font-weight: unset;
    src: url('../fonts/openSans/OpenSans-Italic-webfont.woff')
}

/* SELECTION */

::selection {
    background: var(--main_soft);
    color: var(--grey-90)
}

/* WebKit/Blink Browsers */
::-moz-selection {
    background: var(--main_soft);
    color: var(--grey-90)
}

/* Gecko Browsers */

/* SCROLLBAR */

::-webkit-scrollbar {
    width: 10px;
    height: 5px
}

::-webkit-scrollbar-track {
    background-color: var(--grey-10)
}

::-webkit-scrollbar-thumb {
    background-color: var(--grey-20);
    border-radius: 25px
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%)
}


/* 
  __  __          _        
 |  \/  |  __ _  (_)  _ _  
 | |\/| | / _` | | | | ' \ 
 |_|  |_| \__,_| |_| |_||_|
                           
*/


* {
    box-sizing: border-box
}

html {
    scroll-behavior: smooth
}

body {
    margin: 0;
    font-family: sans-serif;
    padding: 0
}

body,
html,
#root,
.App {
    color: var(--grey-90);
    font-family: 'openSans', sans-serif;
    font-size: 14px;
    height: 100%;
    width: 100%;
}

ul,
li {
    margin: 0;
    padding: 0
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'heebo Light', sans-serif;
    font-weight: unset
}

a {
    color: var(--sub);
    text-decoration: none;
    position: relative
}

a:hover {
    color: var(--sub)
}

a::after {
    border-bottom: 1px solid var(--sub);
    bottom: -7px;
    content: '';
    display: inline-block;
    left: 0;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transform-origin: center;
    transition: 0.25s;
    width: 100%;
}

a:hover::after {
    transform: scaleX(.5)
}

img {
    font-size: 0
}

.icon {
    vertical-align: middle;
}

.slick-slide {
    transition: 0.35s
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.lazyload {
    opacity: 0;
}

.lazyloading {
    opacity: 1;
    transition: opacity 300ms;
}

img.lazyload:not([src]) {
    visibility: hidden;
}

/*
  _ _     __    _ _  
 | | |   /  \  | | | 
 |_  _| | () | |_  _|
   |_|   \__/    |_| 
                     
*/

#error {
    height: 100%;
    padding: 25px;
    width: 100%;
}

#error h1 {
    color: var(--grey-20);
    font-family: 'heebo Light', sans-serif;
    font-size: 100px;
    margin: 0;
}

#error h1 .icon {
    height: 85px;
    margin-top: -20px;
    width: 85px
}

#error h2 {
    font-size: 25px;
    margin: 0 0 25px 0
}

#error .errorContent {
    background-color: white;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 25px;
    position: relative;
    text-align: center;
}

#error .errorContent::after {
    background: linear-gradient(135deg, rgba(250, 188, 52, 1) 25%, rgba(253, 134, 40, 1) 75%);
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    right: 0;
}

#error .childFocus {
    border-radius: 5px;
    flex: 1;
    overflow: hidden;
    position: relative;
}

#error .errorBackHome {
    color: white;
    font-family: 'OpenSansLight', sans-serif;
    font-weight: 300;
    font-size: 20px;
    padding-top: 25px;
    text-align: center;
}

#error .errorBackHome .icon {
    vertical-align: sub
}

/*
  ___                        _        __   _         _      _      
 |_ _|  _ _    _ __   _  _  | |_     / _| (_)  ___  | |  __| |  ___
  | |  | ' \  | '_ \ | || | |  _|   |  _| | | / -_) | | / _` | (_-<
 |___| |_||_| | .__/  \_,_|  \__|   |_|   |_| \___| |_| \__,_| /__/
              |_|                                                  
*/

input,
select {
    border: 0;
    border-radius: 5px;
    font-family: 'OpenSansLight', sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin: 0;
    padding: 5px 15px;
    vertical-align: top;
}

select {
    cursor: pointer
}

input:focus,
select:focus {
    outline-width: 0
}

input[type='submit'] {
    color: white;
    font-family: 'heebo Light', sans-serif
}

input[type='checkbox'],
input[type='radio'] {
    display: none
}

input[type='checkbox']:checked+label,
input[type='radio']:checked+label {
    color: var(--main)
}

input[type='checkbox']:checked+label .checkBoxChecked,
input[type='radio']:checked+label .radioChecked {
    opacity: 1;
    transform: scale(1)
}

.checkBox,
.radio {
    border: 1px solid var(--grey-10);
    border-radius: 3px;
    float: left;
    height: 15px;
    margin-right: 10px;
    padding: 1px;
    width: 15px;
}

.checkBox .checkBoxChecked,
.radio .radioChecked {
    background-color: var(--main);
    border-radius: 3px;
    height: 100%;
    opacity: 0;
    transform: scale(0);
    transition: 0.35s;
    width: 100%;
}

.radio,
.radio .radioChecked {
    border-radius: 50px
}

input[type='checkbox']+label,
input[type='radio']+label {
    border-radius: 5px;
    cursor: pointer;
    display: block;
    padding: 5px;
}

select {
    appearance: none;
    background-color: white;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 15px;
}

::placeholder {
    color: var(--grey-20);
    opacity: 1;
}

@media only screen and (max-width: 768px) {
    select {
        background-color: white
    }
}

/*
  ___         _              _   
 / __|  ___  | |  ___   __  | |_ 
 \__ \ / -_) | | / -_) / _| |  _|
 |___/ \___| |_| \___| \__|  \__|                               

*/

.select {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    font-family: 'openSans', sans-serif;
    font-size: 13px;
    height: 50px;
    max-width: 130px;
    padding: 15px 20px;
    position: relative;
    text-align: left;
    transition: .25s;
    width: 100%;
}

.select:hover {
    background-color: var(--grey-10);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1)
}

.select .selected {
    padding-left: 10px
}

.select .selected .icon {
    height: 15px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
}

.select .options {
    background: white;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    top: 50px;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: .25s;
    z-index: 50;
}

.select:hover .options {
    opacity: 1;
    transform: scaleY(1)
}

.select .options .icon {
    background-color: var(--info);
    border-radius: 5px 0 5px 0;
    bottom: 0;
    color: white;
    height: 25px;
    padding: 3px !important;
    position: absolute;
    right: 0;
    transition: opacity 0.25s;
    width: 25px;
    z-index: 11;
}

.select .option {
    color: var(--grey-50);
    padding: 10px;
    transition: 0.25s;
}

.login_wrapper {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.05);
    display: block;
    left: 50%;
    height: max-content;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    z-index: 102;
    min-height: 350px;
    min-width: 500px;
}

.login_background {
    background-color: black;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 101;
}

.login_wrapper .icon {
    cursor: pointer;
    color: var(--grey-80);
    position: fixed;
    height: 15px;
    top: 10px;
    right: 10px;
    width: 15px;
    z-index: 1;
}

.login {
    height: max-content;
    width: 100%;
    min-height: 350px;
    position: relative;
}

.login .logo {
    margin-top: 25px;
    padding: 0 75px;
    width: 100%;
}

.login .logo.small {
    width: 70px;
    margin: 30px auto;
    display: block;
    padding: 0;
}

.login_content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100% - 123px);
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}

.login_content h2 {
    color: var(--grey-50);
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.login_buttons {
    display: flex;
    justify-content: space-evenly;
}

.login_buttons.sign_up .button {
    min-width: 150px;
}

.login_buttons.sign_up .button_wrapper {
    max-width: 150px;
}

.login_buttons .button_wrapper {
    max-width: 150px;
}

.login_buttons .button {
    margin: 0;
    opacity: 1;
    z-index: 999999;
    min-width: 150px;
    width: 100%;
}

.login_buttons.forgot {
    flex-direction: column;
}

.login_back {
    right: unset;
    left: 15px;
}

.login_input {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.login_buttons {
    display: flex;
    margin-top: 10px;
}

.login_input .osn_input_wrapper {
    width: 300px;
}

.login_input .button_wrapper .button_shadow,
.button_icon_wrapper .button_shadow {
    width: 100%;
}

.login_input .osn_input_wrapper .icon {
    color: var(--main);
    opacity: 1;
}

.login_input .osn_input {
    color: var(--grey-50);
}

.login_input h2 {
    max-width: 300px;
    min-width: 300px;
    font-size: 10px;
}

.login_input .button_regular {
    margin-top: 10px;
}

.login_input .inputFieldWrapper {
    width: 100%;
}

.login_input .buttonRegular {
    margin: 6px;
}

.itsme {
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    height: 100px;
    border: 1px solid var(--grey-10);
    box-sizing: content-box;
}

.itsme .itsme_img {
    height: 50%;
    width: 100px;
    position: relative;
    align-self: center;
}

.itsme .itsme_img img {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    height: 100%;
    transform: translate(-50%);
}

.itsme .icon {
    position: relative;
    align-self: center;
    width: 25px;
    height: 25px;
    top: unset;
    right: unset;
}

.itsme h2 {
    font-size: 15px;
    font-weight: 900;
}

.itsme span:first-of-type {
    line-height: 20px;
    color: var(--grey-80);
    margin-bottom: 5px;
    display: block;
}

.itsme span:last-of-type {
    font-size: 13px;
    color: var(--grey-50);
}

.login .error_message {
    font-size: 20px;
    letter-spacing: 1px;
    background-color: var(--error);
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin: 25px auto;
    display: block;
    width: max-content;
}

.pbc_content {
    padding: 25px;
    width: 500px;
}

.pbc_content_block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
}

.pbc_content_block .osn_checkbox label:hover {
    background-color: unset;
}

.pbc_content_block .osn_checkbox {
    margin-left: 15px;
}

.pbc_content_block .checkBox,
.radio {
    width: unset;
    border: unset;
    height: unset;
}

.pbc_content_block .checkBox .buttonWrapper {
    margin: 0 auto;
}

.pbc_content_block .checkBox .inputFieldWrapper {
    width: 90%;
}

.pbc_content h2 {
    width: 100%;
    border-bottom: 1px dotted var(--main);
    color: var(--main);
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 15px;
}

.pbc_input {
    display: flex;
    align-items: center;
    width: 50%;
}

.pbc_input input::placeholder {
    color: var(--grey-30)
}

.pbc_content .button_wrapper {
    margin: 5px auto;
}

.pbc_content .osn_input_wrapper {
    min-width: 200px;
}

.pbc_content .button {
    margin: 0;
    opacity: 1;
    z-index: 999999;
    min-width: 150px;
    width: 100%;
}

.pbc_input .pbc_input_name {
    min-width: 150px;
    text-align: right;
    margin-right: 15px;
    color: var(--grey-90);
}

.pbc_contact_options {
    margin: 10px 0;
}

/*
  __  __               
 |  \/  |  __ _   _ __ 
 | |\/| | / _` | | '_ \
 |_|  |_| \__,_| | .__/
                 |_|   
*/

#mapContainer {
    position: fixed;
    padding: 25px;
    z-index: 9999
}

#mapContainer #mapSelectionWrapper {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

#mapContainer,
#mapContainer #mapSelectionWrapper {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.25s;
}

#mapContainer #mapSelectionWrapper .mapBlock {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%
}

#mapContainer .mapSelectionOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

/* Map top navigation */

#mapContainer .mapSelectionNav {
    background-color: var(--grey-10);
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1010;
}

#mapContainer .mapSelectionNav .mapBtn::after {
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.75;
    position: absolute;
    right: 0;
    z-index: -1;
}

#mapContainer .mapSelectionNav .removeAll::after {
    background: var(--error)
}

#mapContainer .mapSelectionNav .confirmMap::after {
    background: var(--success)
}

#mapContainer .mapSelectionNav .closeMap {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: var(--grey-90)
}

#mapContainer .mapSelectionNav .closeMap .icon {
    height: 15px;
    width: 15px
}

#mapContainer .mapSelectionNav .closeMap:hover {
    background-color: var(--grey-20)
}

#mapContainer .mapSelectionNav .closeMap::after {
    background: white
}

#mapContainer .mapBtn {
    color: white;
    cursor: pointer;
    font-size: 12px;
    height: auto;
    padding: 8px;
    position: relative;
    text-transform: uppercase;
    transition: 0.25s;
    z-index: 999;
    text-align: center;
}

#mapContainer .mapBtn .icon {
    height: 17px;
    transform-origin: center;
    width: 17px
}

#mapContainer .mapWrapper .select .selected {
    font-size: 15px;
    padding-left: 10px;
    padding-top: 5px;
}

#mapContainer .mapWrapper .select .options {
    top: 60px
}

.mapWrapper #mapInfo {
    left: 0 !important;
}

@media only screen and (max-width: 768px) {

    #mapContainer .mapWrapper #map,
    #mapContainer .mapWrapper .mapDrawerTop,
    #mapContainer .mapWrapper #mapDrawer .mapMessage,
    #mapContainer .mapWrapper #mapDrawer .mapDrawerPin {
        display: none
    }

    #mapContainer .mapWrapper {
        padding-left: 0
    }

    #mapContainer .mapWrapper #mapDrawer {
        width: 100%
    }
}

/*
  _                      _   _                                            
 | |     ___   __ _   __| | (_)  _ _    __ _     _ __   __ _   __ _   ___ 
 | |__  / _ \ / _` | / _` | | | | ' \  / _` |   | '_ \ / _` | / _` | / -_)
 |____| \___/ \__,_| \__,_| |_| |_||_| \__, |   | .__/ \__,_| \__, | \___|
                                       |___/    |_|           |___/       
*/

.loadingScreen {
    left: 50%;
    max-width: 150px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

/* Dev mode ribbon */
.ribbon-wrapper {
    width: 110px;
    height: 110px;
    overflow: hidden;
    position: fixed;
    top: -3px;
    left: -3px;
    z-index: 999;
    pointer-events: none;
}

.ribbon {
    font: bold 15px sans-serif;
    text-align: center;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 25px;
    left: -30px;
    width: 140px;
    background-color: var(--sub);
    color: #fff;
}




.img_servicefilters {
    object-fit: cover;
}



.auto_suggestion:hover::after {
    background-color: var(--main) !important;
    cursor: pointer;
}