/*
     ___                  _    
    / __| ___ __ _ _ _ __| |_  
    \__ \/ -_) _` | '_/ _| ' \ 
    |___/\___\__,_|_| \__|_||_|
       
    All the following style are applied
    to the every search related component.

    Table of contents:
    -  # Search engine
    -  # Search bar
        - Input fields
        - Location input tags
        - Location button
        - Search bar municipality project version
        - Search bar button
        - Small search bar
        - Top search bar
        - Search bar extra
    -  # Last search
    -  # Search options
    -  # Search autosuggestion 

*/

/* # Search engine */
.home .navLogo {
  visibility: hidden;
}
#searchEngineWrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 5;
}
#logo_municipality_local_wrapper {
  width: 180px;
  height: 90px;
  margin: 25px auto 10px;
  position: relative;
  transition: 0.5s;
}
#logo_municipality_local_wrapper:hover {
  transform: scale(1.05);
}
#logo_municipality_local_wrapper:hover + h1 {
  opacity: 1;
  visibility: visible;
}
.logo_municipality_local {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.logo_municipality_wrapper {
  position: absolute;
  height: 50px;
  top: 54%;
  transform: translate(-50%, -50%);
  left: 55.8px;
  width: 50px;
}
.logo_municipality {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.searchEngine {
  margin-bottom: 65px;
  max-width: 870px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
  min-height: 278px;
}
/* .searchEngine.active{
  margin: 150px 0 100px;
} */
.searchEngine h1 {
  color: var(--grey-80);
  font-size: 13px;
  margin: 10px 0;
  opacity: 0;
  transition: 0.25s;
  visibility: hidden;
}
.searchEngine h1 strong {
  color: var(--main);
}
@keyframes logoIntro {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.searchEngine .logo {
  /* animation: logoIntro 1s; */
  animation-iteration-count: 1;
  height: 90px;
  margin: 0 auto;
  max-width: 450px;
  opacity: 1;
  padding: 0 25px;
  transition: 0.5s;
  transform: translateY(0);
  width: 100%;
}
.searchEngine .logo:hover {
  transform: scale(1.05);
}
.searchEngine .logo:hover + h1 {
  opacity: 1;
  visibility: visible;
}
.nis .searchEngine {
  max-width: 700px;
}
@media only screen and (max-width: 768px) {
  #logo_municipality_local_wrapper {
    margin: 25px auto;
  }

  .searchEngine h1 {
    display: none !important;
  }
  .searchEngine .logo {
    height: 65px;
    margin-bottom: 25px;
    padding: 0;
  }
  .searchEngine .logo:hover {
    transform: unset;
  }
  .searchEngine .logo:hover + h1 {
    opacity: 0;
  }
}

/* # Search bar */

@keyframes searchBarIntro {
  0% {
    opacity: 0;
    transform: translateX(-450px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.searchBar {
  animation-iteration-count: 1;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 5px auto;
  max-height: 50px;
  max-width: 724px;
  opacity: 1;
  position: relative;
  transform: translateX(0);
  width: 100%;
  z-index: 1;
}
.nis .searchBar {
  max-width: 100%;
}
.searchBar form {
  height: 100%;
  max-height: 50px;
}
.searchBar .btocb.select {
  border-left: none !important;
  border-radius: 20px 0 0 20px;
  height: auto;
  padding: 9px 0;
}
.searchBar .btocb.select:hover {
  border-radius: 0 !important;
}
.searchBar .select {
  color: var(--grey-50);
}
.searchBar .select .selected {
  padding-left: 20px;
}
.searchBar .select .options {
  top: 35px;
}
.searchBar .btocb.select {
  border-left: 1px solid var(--grey-10);
}

/* Input fields */

.searchBar .label {
  display: none;
}
.searchBar .searchInput {
  flex: 1;
  position: relative;
}
.searchBar .searchInput label {
  width: 100%;
}
.searchBar .searchInput .emptyValue {
  align-items: center;
  background: var(--grey);
  color: var(--grey-50);
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
}
.searchBar .searchInput .emptyValue .icon {
  height: 15px;
  width: 15px;
}
.searchBar .searchInput:first-child input {
  border-radius: 0;
}
.searchBar .searchInput input {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  color: var(--grey-90);
  font-family: "openSans", sans-serif;
  font-size: 13px;
  height: 50px;
  padding: 15px 10px;
  position: relative;
  width: 100%;
  z-index: 100;
}
.searchBar .searchInput input::placeholder {
  color: var(--grey-50);
}
.searchBar .searchInput::after {
  border-bottom: 1px solid var(--error);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: 0.5s;
  z-index: 100;
}
.searchBar .searchInput.empty::after {
  transform: scaleX(1);
}
.searchBar .searchInput input:hover,
.searchBar .searchInput input:focus {
  background-color: white;
}
.searchBar .searchInput.locationInput input {
  padding: 0;
  z-index: 98;
}
.searchBar .searchInput.locationInput {
  background-color: white;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  padding: 15px 10px;
  max-height: 50px;
  width: 20rem;
}

/* Location input tags */

#location_tag {
  align-items: center;
  background-color: white;
  border: 1px solid var(--sub);
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  color: var(--grey-50);
  display: flex;
  font-size: 11px;
  height: 100%;
  margin-right: 10px;
  max-width: 250px;
  min-height: 25px;
  min-width: max-content;
  white-space: nowrap;
  width: max-content;
}
#location_tag span {
  margin-right: 10px;
  padding-right: 10px;
}
#location_tag span:last-child {
  margin-right: 10px;
  padding-right: 0;
}
#location_tag span.location_tagpostal {
  border-right: 1px solid var(--grey-50);
}
#location_tag .location_tagIcon {
  border-radius: 25px;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
  transition: 0.25s;
}
#location_tag .location_tagIcon:hover {
  background-color: var(--grey-5);
}

/* Location button */

.searchBar .searchLocation {
  background-image: url("../imgs/map_icon.jpg");
  background-position: center;
  background-size: 100%;
  border-left: 1px solid var(--grey-10);
  border-right: 1px solid var(--grey-10);
  color: var(--grey-50);
  cursor: pointer;
  height: 50px;
  position: relative;
  transition: 0.35s;
  width: 50px;
}
.searchBar .searchLocation:hover {
  background-size: 150%;
}
.searchBar .searchLocation:hover .mapMarker .icon,
.searchBar .searchLocation:hover .mapMarkerFill .icon {
  animation: MarkerPopUpAnimation 1.5s infinite ease-out;
  color: var(--error);
}
.searchBar .searchLocation .mapMarker,
.searchBar .searchLocation .mapMarkerFill {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.25s;
  width: 30px;
  height: 30px;
}
.searchBar .searchLocation .mapMarker {
  color: var(--sub);
  opacity: 1;
}
.searchBar .searchLocation .mapMarkerFill {
  opacity: 0;
}
.searchBar .searchLocation:hover .mapMarker {
  opacity: 0;
}
.searchBar .searchLocation:hover .mapMarkerFill {
  opacity: 1;
}
.searchBar .searchLocation .icon {
  height: 100%;
  width: 100%;
}

/* Search bar municipality project version */

.searchBar .select.sub_muni_selection {
  border-left: 1px solid var(--grey-10);
}
.searchBar .select.sub_muni_selection .selected {
  max-width: calc(100% - 32px);
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}
.searchBar .select.sub_muni_selection .options {
  max-height: 360px;
  overflow: auto;
  top: 50px;
}
.topSearchBarWrapper .searchBar .select.sub_muni_selection .options,
.bp .searchBar .select.sub_muni_selection .options {
  top: 40px;
}
.searchBar .select.sub_muni_selection .options .option {
  overflow: hidden;
  padding: 5px 8px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searchBar .select.sub_muni_selection .options .option::after {
  background-color: var(--main);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.25s;
}
.searchBar .select.sub_muni_selection .options .option:hover::after {
  opacity: 0.2;
}
.searchBar .select.sub_muni_selection .options .option.active::after {
  opacity: 0.4;
}
.searchBar .select.sub_muni_selection .options .option.active {
  color: var(--grey-80);
}
.searchBar .select.sub_muni_selection .options .option:hover {
  color: var(--grey-50);
}
.searchBar .select.sub_muni_selection:hover {
  background-color: var(--grey-5);
}

/* Search bar button */

.searchBar .searchBtn {
  border-radius: 0 5px 5px 0;
  height: 50px;
  overflow: hidden;
  position: relative;
  transition: 0.25s;
  width: 50px;
}
.searchBar .searchBtn::before {
  background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
  bottom: -10px;
  content: "";
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
}
.searchBar .searchBtn a {
  display: block;
  height: 100%;
  width: 100%;
}
.searchBar .searchBtn a::after {
  content: unset;
}
.searchBar .searchBtn input {
  display: none;
}
.searchBar .searchBtn .icon {
  color: white;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  transition: 0.5s;
}
.searchBar .searchBtn .icon:first-child {
  height: 25px;
  opacity: 0;
  transform: rotate(-45deg) translate(-10%, -70%);
  transform-origin: center;
  width: 25px;
}
.searchBar .searchBtn:hover .icon:first-child {
  height: 25px;
  opacity: 1;
  transform: rotate(0deg) translate(-50%, -50%);
  width: 25px;
}
.searchBar .searchBtn .icon:nth-of-type(2n) {
  height: 25px;
  opacity: 1;
  width: 25px;
}
.searchBar .searchBtn:hover .icon:nth-of-type(2n) {
  height: 25px;
  opacity: 0;
  transform: rotate(45deg) translate(-15px, -5px);
  width: 25px;
}
.sub_muni_selection .subMuniTitle {
  overflow: hidden;
  color: var(--grey-30);
  font-size: 11px;
  display: block;
  padding: 0;
  margin: 5px;
}

/* Small search bar */

.searchBarWrapper.small {
  height: 40px;
  margin-bottom: 10px;
  max-width: 700px;
  width: 100%;
  display: flex;
  align-items: center;
}
.searchBarWrapper.small .searchBar {
  height: 100%;
  margin: 0;
  max-height: 40px;
}
.searchBarWrapper.small .searchBar .searchLocation,
.searchBarWrapper.small .searchBar .searchBtn {
  height: 40px;
  width: 40px;
}
.searchBarWrapper.small .searchBar .searchBtn .icon:nth-of-type(2n),
.searchBarWrapper.small .searchBar .searchBtn:hover .icon:first-child {
  height: 20px;
  width: 20px;
}
.searchBarWrapper.small .searchBar .searchInput input,
.searchBarWrapper.small .searchBar .select {
  padding: 10px;
}
.searchBarWrapper.small .searchBar .searchInput.locationInput input {
  min-width: 100px;
  padding: 0;
}
.searchBarWrapper.small .searchBar .btocb.select .options {
  top: 40px;
}
.searchBarWrapper.small .searchBar .searchLocation .mapMarker,
.searchBarWrapper.small .searchBar .searchLocation .mapMarkerFill {
  height: 25px;
  width: 25px;
}
.searchBarWrapper.small .searchBar .searchInput input,
.searchBarWrapper.small .searchBar .searchInput.locationInput,
.searchBarWrapper.small .searchBar .select {
  height: 40px;
}
.searchBarWrapper.small .searchBar .select {
  min-width: 180px;
}

@media only screen and (max-width: 768px) {
  .searchBarWrapper.small {
    height: 80px;
    margin-bottom: 10px;
  }
  /* .nis .searchBarWrapper.small .searchInput {
    min-width: 100%;
  } */
  .searchBarWrapper.small .searchBar {
    max-height: 80px;
  }
  .locationTag {
    cursor: pointer;
  }
  .locationTag .removeTag .icon:hover {
    background-color: transparent;
  }
  .topSearchBarWrapper {
    flex-flow: column;
  }
  .topSearchBar {
    flex-wrap: wrap;
  }
  .topSearchBar #topLogo {
    display: flex;
    margin-bottom: 10px;
    margin-left: 7px;
  }
}

/* Top search bar */

.topSearchBar {
  background-color: var(--grey);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
  padding: 10px 10px 0 10px;
  position: relative;
  top: 0;
  transition: 0.5s;
  z-index: 50;
}
.topSearchBar.float {
  left: 0;
  position: fixed;
  right: 0;
  top: -95px;
}
.topSearchBarWrapper {
  display: flex;
  /* flex-flow: column; */
  align-items: center;
  margin: 0 auto;
  max-width: 700px;
  position: relative;
  width: 100%;
}
.SearchBarWrapper.active {
  z-index: 160;
}

/* .nis .topSearchBarWrapper {
  max-width: 500px;
} */
.topSearchBar::after {
  background: var(--grey-20);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
/* .nis .topSearchBar .searchBar {
  max-width: 500px;
} */

.topSearchBar .navLogo {
  display: block;
  width: 25px;
}
.topSearchBar .navLogo::after {
  content: unset;
}

/* .topSearchBar .navLogo img{ width: 100%; } */
.topSearchBar #topLogo {
  opacity: 0;
  transition: 0.05s;
}

@keyframes MarkerPopUpAnimation {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-15px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}
@media only screen and (max-width: 890px) {
  .topSearchBar .searchBar {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .searchBar {
    max-height: 100px;
  }
  .searchBar .searchLocation {
    display: none;
  }
  .searchBar .searchLocation,
  .searchBar .searchInput:first-child {
    min-width: 100%;
  }
  .searchBar .searchInput:first-child input {
    border-radius: 5px 5px 0 0;
  }
  .searchBar .searchInput {
    min-width: calc(100% - 50px);
  }
  .searchBar .searchInput input {
    border-radius: 0 0 0 5px;
  }
  .searchBar .searchBtn {
    border-radius: 0 0 5px 0;
  }
  .nis .searchBar .searchBtn {
    border-radius: 0 5px 5px 0;
  }
  .topSearchBar {
    padding: 10px 10px 0 10px;
  }
  .topSearchBar .searchBar {
    height: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  /* .nis .searchBar .searchInput {
    min-width: 100% !important;
  } */
  .nis .searchBar .searchBtn {
    border-radius: 0 0 5px 0;
  }
  .nis .searchBar .select.sub_muni_selection {
    border: none;
    border-radius: 0 0 0 5px;
  }
}

/* Search bar extra */

.searchbarExtra {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  min-height: 30px;
}
.searchbarExtra .searchOptions,
.searchbarExtra .locationTagWrapper {
  flex: 1;
}
@media only screen and (max-width: 768px) {
  .searchbarExtra .searchOptions,
  .searchbarExtra .locationTag.moreTags {
    display: none;
  }
  .searchBarWrapper .toggleView {
    border-radius: 5px 0 0 0 !important;
  }
}

/* # Last search */
.searchBarLastSearch {
  color: var(--grey-90);
  font-size: 12px;
  /* margin: 0 0 10px; */
  padding: 0 5px;
  width: max-content;
}
.searchBarLastSearch a {
  color: var(--grey-50);
  padding: 0 10px;
  border-right: 1px solid var(--grey-50);
}
.searchBarLastSearch a:last-of-type {
  border-right: unset;
}
.searchBarLastSearch a:hover {
  color: var(--sub);
}

.searchBarLastSearch_title {
  text-transform: capitalize;
}

/* # Browser actions */
.searchBarBrowerActions {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* # Search options */

.searchOptions {
  border-radius: 50px;
  color: var(--grey-50);
  display: flex;
  height: 32px;
  gap: 10px;
  max-width: max-content;
  position: relative;
  text-align: center;
  transition: 0.5s;
  width: max-content;
}
.searchOptions::after {
  border-bottom: 1px solid var(--grey-20);
  bottom: -1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s;
  z-index: -1;
}
.searchOptions.active::after {
  transform: scaleX(1);
}
.so_btn {
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  width: max-content;
  transition: 0.25s;
  padding: 7px 10px;
  color: var(--sub);
  border: 1px solid var(--sub);
  display: flex;
  height: 32px;
  max-height: 32px;
  align-items: center;
}
.so_btn:hover {
  background-color: var(--sub);
  color: white;
}
.so_btn.active:hover {
  background-color: white;
  color: var(--sub);
}
.so_btn .icon {
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  padding: 7px;
  width: 30px;
}

.searchOptions .icon {
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  padding: 7px;
  width: 30px;
}
.searchOptions .so_btocb {
  background: transparent;
  border-radius: 5px;
  font-size: 12px;
  height: 100%;
  min-width: 110px;
  padding: 9px 5px;
}
.searchOptions .so_btocb .selected .icon {
  padding: 0;
  right: 10px;
}
.searchOptions .so_btocb:hover {
  /* background-color: var(--grey-10); */
  box-shadow: none;
}
.searchOptions .so_btocb .options {
  border-radius: 5px;
  top: 36px;
}
.searchOptions .select .option:hover {
  background-color: var(--grey-5);
}
.searchOptions .so_btocb .selected .icon {
  right: 10px;
}
.searchOptions .so_bar {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 100%;
  justify-content: center;
  transition: 0.5s;
}
.searchOptions .so_view_toggle {
  border-left: 1px solid var(--grey-20);
  display: flex;
  gap: 10px;
  height: auto;
  padding-left: 10px;
}
.searchOptions .so_view_toggle span.active,
.searchOptions .so_view_toggle span.active:hover {
  color: var(--sub);
}
.searchOptions .so_view_toggle span:hover .icon {
  background-color: var(--grey-10);
  color: var(--grey-50);
}
.searchOptions .so_btocb_info_wrapper {
  position: relative;
}
.searchOptions .so_btocb_info_wrapper:hover .icon {
  /* background-color: var(--grey-10); */
  border-radius: 5px;
  color: var(--info);
}
.searchOptions .so_btocb_info_wrapper .so_btocb_info {
  background-color: white;
  border: 1px solid var(--info);
  border-radius: 5px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  color: var(--grey-50);
  font-size: 12px;
  padding: 10px;
  left: -25px;
  top: 40px;
  text-align: left;
  transform: scale(0);
  transform-origin: left top;
  transition: 0.25s;
  position: absolute;
  width: max-content;
  z-index: 5;
}
.searchOptions .so_btocb_info_wrapper .so_btocb_info::after {
  border-bottom: 5px solid var(--info);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  left: 35px;
  top: -5px;
  width: 0;
}
.searchOptions .so_btocb_info_wrapper:hover .so_btocb_info {
  transform: scale(1);
}
.bp .searchOptions {
  display: none;
}

/* filters */

@keyframes searchFilters {
  0% {
    transform: translate3D(-50%, -50%, 0) scale(0);
  }
  100% {
    transform: translate3D(0, 0, 0) scale(1);
  }
}

.searchOptions.filter .so_btn {
  max-width: 103px;
  max-height: 32px;
  display: block;

  transition: 0.25s;
  width: 103px;
  height: 32px;
  cursor: default;
  padding: 0;
}
.searchOptions.filter .icon {
  position: absolute;
  top: 0;
  right: 0;
}

.searchOptions.filter.active .so_btn {
  max-width: 390px;
  max-height: 376px;
  width: 390px;
  height: 376px;
  background: white;
  color: var(--sub);
  animation: searchFilters 0.5s;
  position: relative;
}

.searchOptions.filter.active #filterTitle {
  width: 100%;
  justify-content: space-between;
}
#filterTitle {
  cursor: pointer;
  height: 32px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0 10px;
  justify-content: center;
}

.searchOptions.filter {
  display: flex;
  flex-direction: column;
  max-width: 103px;
  margin-left: auto;
}

.filtersMainWrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 0;
  transform: scale(0);
  transform-origin: top left;
  height: 100%;
}

.filtersMainWrapper.active {
  min-height: 340px;
  transform: scale(1);
  transition: 0.25s;
}

.searchFilter {
  width: 160px;
  cursor: pointer;
  border: 1px solid var(--grey-20);
  margin: 8px 5px;
  align-items: center;
  display: flex;
  font-size: 12px;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 160px;
  max-height: 100px;
  height: 50px;
  transition: 0.25s;
}

.searchOptions.filter .filtersMainWrapper .icon {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: white;
  box-sizing: content-box;
  border-radius: 100%;
  border: 1px solid var(--grey-20);
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  padding: 7px;
  position: relative;
  left: -15px;
  transition: 0.25s;
}
.searchFilter span {
  width: 100%;
  margin-left: -10px;
  text-align: left;
  color: var(--grey-80);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.searchOptions.filter .icon .svg {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  padding: 7px;
  background-color: var(--grey-10);
  transition: 0.25s;
}
.searchFilter .icon .svg img {
  transition: 0.25s;
}

.searchFilter.active,
.searchFilter.active .icon {
  border: 1px solid var(--main);
}
.searchFilter.active .icon .svg {
  background-color: var(--main);
}
.searchFilter.active .icon .svg img {
  filter: invert(100%);
}

.filterWrapper {
  padding-left: 15px;
}
.filterWrapper.second {
  padding-right: 10px;
  padding-left: unset;
}

.filterWrapper.second .icon {
  order: 1;
  left: unset !important;
  right: -15px;
}
.filterWrapper.second span {
  margin-left: 0;
  text-align: right;
  margin-right: -10px;
}

#filterSearchButton {
  width: 100%;
  background: var(--sub);
  color: white;
  position: absolute;
  bottom: 32px;
  left: 0;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;
}
#filterSearchButton:hover {
  opacity: 0.9;
}

.searchBarWrapper.small .searchOptions.filter {
  padding-left: 10px;
}
.searchOptions a::after {
  content: unset;
}

/* # Search autosuggestion */

.autosuggestion {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  left: 0;
  overflow: hidden;
  padding: 7px 0;
  position: absolute;
  right: 0;
  top: 55px;
  width: 100%;
  z-index: 99;
}
#results .autosuggestion,
.bp .autosuggestion {
  top: 45px;
}
.autosuggestion .autosuggestion_content {
  height: 100%;
  max-height: 200px;
  overflow: auto;
}
.searchBar .searchInput.locationInput .autosuggestion {
  z-index: 97;
}
.autosuggestion .as {
  color: var(--grey-50);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 34px;
  margin: 2px 0;
  overflow: hidden;
  padding: 8px;
  position: relative;
  white-space: nowrap;
}
.autosuggestion .as .icon-container {
  padding: 1px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.autosuggestion .as .icon {
  height: 100%;
}
.autosuggestion .as .check {
  margin: 0 0 0 10px;
  position: absolute;
  right: 10px;
  top: 11px;
}
.autosuggestion .as::after {
  background-color: var(--main);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.25s;
}
.autosuggestion .as.active::after,
.autosuggestion .as:hover::after {
  opacity: 0.2;
}
.autosuggestion .as:hover {
  color: var(--grey-90);
}
.autosuggestion .as.as_text {
  align-items: center;
  display: flex;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.autosuggestion .as.as_text .icon {
  min-width: 25px;
}
.autosuggestion .as.as_text p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.autosuggestion .as .as_postal {
  border-right: 1px solid var(--grey-20);
  flex: 1;
  margin-right: 15px;
  max-width: 45px;
  min-width: 45px;
  padding-right: 15px;
  width: 100%;
}
.autosuggestion .as .as_type {
  padding-left: 10px;
}
.nis .searchBar .searchInput,
.nis .searchBar .sub_muni_selection {
  flex: 1 1;
  max-width: unset;
  min-width: 230px;
  width: unset;
}
.nis .searchBar .searchInput {
  flex-grow: 2;
}

.searchBarWrapper .toggleView {
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
  background: linear-gradient(135deg, var(--main) 25%, var(--sub) 75%);
  border-radius: 5px 0 0 5px;
}
.searchBarWrapper.small .toggleView {
  height: 40px;
  min-width: 40px;
  width: 40px;
}
.searchBarWrapper .toggleView .icon {
  transition: 0.25s;
  color: white;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.searchBarWrapper .toggleView span {
  top: 50%;
  right: 55px;
  display: block;
  background-color: white;
  border: 1px solid var(--info);
  border-radius: 5px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  color: var(--grey-50);
  font-size: 12px;
  padding: 10px;
  text-align: left;
  transform-origin: right top;
  transition: 0.25s;
  position: absolute;
  width: max-content;
  z-index: 5;
  transform: scale(0) translate(0%, -50%);
  /* transform: scaleX(0) translate(-50%,-50%); */
}
.searchBarWrapper .toggleView span::after {
  border-left: 5px solid var(--info);
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translate(0, -50%);
  width: 0;
}
.searchBarWrapper .toggleView:hover span {
  transform: scale(1) translate(0%, -50%);
}

.searchbarExtra .serviceFilter {
  width: max-content;
  height: 100%;
  margin: 0;
  margin-bottom: 0px;
  margin-bottom: 0 !important;
  border: unset;
  margin-right: 10px;
}
.searchbarExtra .checkBox span {
  padding-top: 0;
}
.searchbarExtra .serviceFilter .checkboxBox {
  margin-right: 5px;
}
@media only screen and (max-width: 1420px) {
  .searchOptions.filter.active {
    max-width: 390px;
  }
}
@media only screen and (max-width: 768px) {
  .searchBar .searchInput .autosuggestion {
    border-radius: 5px;
  }
  .searchBar .searchInput.locationInput .autosuggestion {
    border-radius: 0 0 5px 5px;
  }
}

#patientMessage {
  position: absolute;
  top: 85px;
  right: 30px;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: var(--enterprise);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#patientMessage .icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-left: 15px;
}

/* Small screens (max-width: 480px) */
@media only screen and (max-width: 480px) {
  .topSearchBarWrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .topSearchBarWrapper .searchBar {
    margin-bottom: 10px;
  }
}

/* Medium screens (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .topSearchBarWrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .topSearchBarWrapper .searchBar {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Large screens (max-width: 1024px) */
@media only screen and (max-width: 1024px) {
  .topSearchBarWrapper {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
  }

  .topSearchBarWrapper .searchBar {
    width: auto;
    margin-bottom: 0;
  }
}


