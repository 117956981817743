
/* TEMPORARY CODE */

.mapBlock .mapWrapper #mapDrawer {
    height: max-content !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 0 0 5px 0 !important;
}
.mapWrapper #mapDrawer .mapMessage { margin-bottom: 0 !important; }
.mapWrapper #mapInfo { left: 315px !important; }
.mapBlock  .mapWrapper #mapInfo { left: 315px !important; }
.mapBlock .mapWrapper #mapDrawer .inputFieldWrapper,
.mapWrapper #mapDrawer .mapDrawerPin { display: none !important; }
.mapWrapper #mapDrawer ol { margin-left: 40px !important; }
/* .leaflet-control-layers-toggle input[type="checkbox"], input[type="radio"] { display: inline !important;} */
/* .leaflet-control-layers-selector { display: inline !important;} */
.map_title { z-index: 800 !important;}
/* .video { top: 55% !important;} */
.selectField:hover .selectFieldOptions { opacity: 1 !important; transform: scaleX(1) !important;}
/* TEMPORARY CODE */
.activity.KBO-RSZ001-sub::after {
    background: var(--main_soft) !important;
}

/* code for leaflet 100 percent local map */

.mapOptions .checkBox, .radio {
    border: unset !important;
    border-radius: unset !important;
    float: unset !important;
    height: unset !important;
    margin-right: unset !important;
    padding: 5px !important;
    width: 100%;
}
.rct-item::after, .rct-item:hover::after  {
    transform: scaleX(1);
    border-bottom: none !important;
}
.activeDay .activeDay_description.top::before { width: 30px !important;}
section#brands .ownerList span a::after { content : unset}
/* #results .leaflet-marker-icon::after { content: unset !important;} */
section#brands.temp-1 #brand_info .actions a::after { content: unset !important;}


@media only screen and (max-width: 560px) {
    .contactWrapper #map { height: unset !important;}
} 
@media only screen and (max-width: 500px) { 
    .mapWrapper #mapInfo { left: 15px !important;} 
} 
.slick-track { display: flex !important;}


